import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="de_cover_16x9.png" className="header-img" alt="logo" />
        <img src="de_logo.png" className="logo-img" />
        <div className="copy-cont">
          <p>Danger Elevator is an innovative film, television and interactive media production company founded by writer/producer J. Michael Parker. Our passion is creating genre-bending stories that explore the many perspectives that make up our collective experience.</p>
          <p><span className="redtext">Wanna take a ride?</span></p>
          <p><a href="https://twitter.com/dngrelevator" target="_blank">twitter</a> | <a href="https://www.imdb.me/jmichaelparker" target="_blank">imdb</a> | <a href="https://www.linkedin.com/in/parkerjamie/" target="_blank">linkedin</a></p>
        </div>
      </header>
    </div>
  );
}

export default App;
